/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~animate.css/animate.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --width: 900px;
  color-scheme: only light;
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-bullet-horizontal-gap: 20px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-vertical-gap: 2px;
  --swiper-pagination-top: auto;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-progressbar-size: 24px;
  --swiper-theme-color: var(--ion-color-primary);
}

swiper-container::part(bullet-active) {
  width: 15px;
  height: 15px !important;
}

swiper-container::part(pagination) {
  position: static;
}

.poppins {
  font-family: "Poppins";
}

.montserrat {
  font-family: "Montserrat";
}

.league-spartan {
  font-family: "League Spartan", sans-serif;
}

.raleway {
  font-family: "Raleway", sans-serif;
}

.barlow {
  font-family: "Barlow Semi Condensed", sans-serif;
}

.gloria-hallelujah {
  font-family: "Gloria Hallelujah", cursive;
}

.dark-background {
  background: var(--ion-color-medium-dark);
}

.boxed-content {
  max-width: var(--boxed-size);
  margin: auto;
  padding: 40px 10%;
}
.boxed-content.dark {
  background: var(--ion-color-medium-dark);
}

@media screen and (max-width: 500px) {
  .boxed-content {
    padding: 20px 40px;
  }
}
.app-toolbar {
  --background: black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  height: 85px !important;
  display: flex;
  padding-inline: 10px;
}
.app-toolbar ion-title {
  color: white;
}

.custom-ngx-gallery {
  border: 2px solid red;
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}
ion-modal ion-toolbar {
  --background: white !important;
}
ion-modal ion-toolbar {
  --border-width: 0 !important;
}
ion-modal::part(content) {
  --width: 800px;
  --max-width: 96%;
  background: white !important;
}
ion-modal.simulatorModal {
  --height: 1110px;
}
ion-modal.simulatorModal::part(content) {
  --width: 958px;
}
ion-modal.auto-height {
  --height: auto;
  /*.ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }*/
}
ion-modal.auto-height::part(content) {
  overflow: auto;
  position: relative;
  contain: content;
  max-height: 90vh !important;
}
ion-modal.auto-height::part(content) .modal-content {
  overflow: auto;
}
ion-modal.wide::part(content) {
  --width: 90%;
  --max-width: 1200px;
}
ion-modal.modal-address {
  --border-radius: 20px;
}
ion-modal.modal-address::part(content) {
  padding: 30px;
}
ion-modal.categories-modal {
  --border-radius: 20px;
}
ion-modal.categories-modal::part(content) {
  --width: 690px !important;
  padding: 20px;
}
ion-modal.order-detail-modal {
  --border-radius: 20px;
  --height: auto;
}
ion-modal.order-detail-modal::part(content) {
  padding: 30px;
  max-height: 90vh !important;
}
ion-modal.order-repeat-modal {
  --border-radius: 20px;
  --height: auto;
}
ion-modal.order-repeat-modal::part(content) {
  padding: 20px;
  max-height: 90vh !important;
}
ion-modal.order-report-incident-modal {
  --border-radius: 20px;
  --height: auto;
}
ion-modal.order-report-incident-modal::part(content) {
  padding: 20px;
  max-height: 90vh !important;
}

.popup-confirm {
  --backdrop-opacity: 0.4;
}
.popup-confirm::part(content) {
  --max-width: 80%;
  width: 540px;
}

.popup-product-added {
  --backdrop-opacity: 0.4;
  --max-width: 90%;
}
@media only screen and (min-width: 768px) {
  .popup-product-added {
    --max-width: 400px;
  }
}

.popup-product-qty {
  --backdrop-opacity: 0.4;
  --min-height: 320px;
}
@media only screen and (min-width: 768px) {
  .popup-product-qty {
    --width: 500px;
  }
}
.popup-product-qty::part(content) {
  --max-width: 80%;
  width: 540px;
}

@media only screen and (min-width: 768px) {
  .promotion-dialog {
    --height: 500px;
  }
}

@media only screen and (min-width: 768px) {
  .popup-before-pay {
    --width: 800px;
  }
}
.popup-before-pay ion-slide {
  width: 40%;
  max-width: 250px;
  height: fit-content;
}

.popup-pickup-points {
  --height: 700px;
}

.popup-add-document {
  --backdrop-opacity: 0.4;
  --height: 800px;
  --max-height: calc(95vh);
  --width: 700px;
  --max-width: calc(100vh);
}

.popup-grouped-binding-individual-settings::part(content) {
  --width: 355px;
}

.modal-width-400::part(content) {
  --width: 400px;
}

.popup-browser-share {
  --backdrop-opacity: 0.4;
  --border-radius: 20px;
}
.popup-browser-share::part(content) {
  --max-width: 80%;
  width: 631px;
}

.popup-tooltip::part(content) {
  width: 350px;
  --height: 620px;
  --max-height: 80%;
  --max-width: 80%;
  overflow: hidden !important;
}

.popup-bonus-warning {
  --border-radius: 30px;
}

tooltip.tooltip {
  background-color: var(--app-black) !important;
  color: var(--ion-color-primary-contrast) !important;
  border-radius: 10px !important;
  font-size: 14px;
  font-weight: 400;
}
tooltip.tooltip.white-tooltip {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;
}
tooltip.tooltip.white-tooltip:after {
  border-color: white transparent transparent transparent;
}
tooltip.tooltip.red-tooltip {
  background-color: var(--ion-color-danger) !important;
  color: white !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;
}
tooltip.tooltip.red-tooltip:after {
  border-color: white transparent transparent transparent;
}
tooltip.tooltip.pink-tooltip {
  background-color: #FF007F !important;
  color: white !important;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;
}
tooltip.tooltip.pink-tooltip:after {
  border-color: #FF007F transparent transparent transparent;
  transform: scaleY(-1) !important;
}

ion-footer ion-grid {
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
}

ion-button {
  --border-width: 2px;
}
ion-button.no-padding-button::part(native) {
  padding: 0 !important;
}

.banner-img {
  width: 150px;
  margin: auto;
}

.border {
  border: 1px solid black;
}

.show-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:root {
  /* Set the font family of the entire app */
  --ion-font-family: "Barlow Semi Condensed";
}

body {
  font-family: "Barlow Semi Condensed";
}

ion-button {
  font-family: "Barlow Semi Condensed", sans-serif;
}

.form-content {
  --background: var(--page-background);
  --padding-start: var(--page-margin);
  --padding-end: var(--page-margin);
  --padding-top: var(--page-margin);
  --padding-bottom: var(--page-margin);
}
.form-content .auth-title {
  font-weight: bolder;
}
.form-content .inputs-list {
  --ion-item-background: var(--page-background);
}
.form-content .inputs-list ion-item {
  --border-color: var(--ion-color-primary);
}
.form-content .inputs-list .label_item {
  color: var(--ion-color-primary);
}
.form-content .inputs-list .input-item {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}
.form-content .inputs-list .error-container .error-message {
  margin: calc(var(--page-margin) / 2) 0px;
  display: flex;
  align-items: center;
  color: var(--ion-color-danger);
  font-size: 18px;
}
.form-content .inputs-list .error-container .error-message ion-icon {
  padding-inline-end: calc(var(--page-margin) / 2);
  margin-right: 10px;
}
@media (max-width: 768px) {
  .form-content .inputs-list .error-container .error-message {
    font-size: 14px;
  }
}

.global-error-container .error-message {
  padding-left: 20px;
  margin: calc(var(--page-margin) / 2) 0px;
  display: flex;
  align-items: center;
  color: var(--ion-color-danger);
  font-size: 14px;
}
.global-error-container .error-message ion-icon {
  padding-inline-end: calc(var(--page-margin) / 2);
}

.line {
  width: 100%;
  border: 2px solid var(--ion-color-primary);
}

@media (min-width: 576px) {
  .padding-desktop {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.main-wrapper {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (min-width: 1400px) {
  .main-wrapper {
    padding-inline: 60px;
  }
}
@media only screen and (max-width: 1399px) {
  .main-wrapper {
    padding-inline: 20px;
  }
}
.link-container div {
  text-align: center;
  font-weight: 600;
  font-size: 12px;
}
.link-container a {
  color: black;
}
.link-container a:hover {
  color: var(--ion-color-primary);
}

ion-modal.auto-height {
  --height: auto;
}
ion-modal.auto-height::part(content) {
  overflow: auto;
  position: relative;
  contain: content;
  max-height: 70vh;
}
ion-modal.auto-height::part(content) .modal-content {
  overflow: auto;
}

@media (max-width: 576px) {
  ion-modal.auto-height::part(content) {
    overflow: auto;
    max-height: 100vh;
  }
}
ion-toolbar {
  --padding-start: 10px;
  --padding-end: 10px;
}

.commission-type.wallet {
  color: #4F4F4F;
}

.commission-type.bank {
  color: #FFECBA;
}

.commission-type.fee {
  color: #C1ECFF;
}

.commission-type.rollback {
  color: #FFC5E2;
}

.commission-status-complete {
  color: #7453a2;
}

.commission-status-rejected {
  color: rgb(223, 95, 35);
}

.commission-status.pending {
  background-color: #ffc933;
}

.commission-status.complete {
  background-color: #7453a2;
}

.commission-status.rejected {
  background-color: rgb(223, 95, 35);
}

.commission-status.expired {
  background-color: #d8e7e9;
}

.commission-status.error {
  background-color: #e00007;
}

.print-setting-badges {
  --background: white;
  --color: var(--ion-color-medium);
  border: 1px solid var(--ion-color-medium);
  margin-right: 5px;
  margin-bottom: 5px;
}

.divisor {
  border-bottom: 0.5px solid;
  height: 1px;
  width: 100%;
  border-color: rgb(204, 204, 204);
}

:root {
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59,89,152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255,255,255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
  --ion-color-google: #cc181e;
  --ion-color-google-rgb: 204,24,30;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255,255,255;
  --ion-color-google-shade: #b4151a;
  --ion-color-google-tint: #d12f35;
  --ion-color-twitter: #00aced;
  --ion-color-twitter-rgb: 0,172,237;
  --ion-color-twitter-contrast: #ffffff;
  --ion-color-twitter-contrast-rgb: 255,255,255;
  --ion-color-twitter-shade: #0097d1;
  --ion-color-twitter-tint: #1ab4ef;
  --ion-color-whatsapp: #128c7e;
  --ion-color-whatsapp-rgb: 61, 194, 255;
  --ion-color-whatsapp-contrast: #128c7e;
  --ion-color-whatsapp-contrast-rgb: 255, 255, 255;
  --ion-color-whatsapp-shade: #ffffff;
  --ion-color-whatsapp-tint: #dcf8c6;
  --ion-color-whatsapp-btn: #ffffff;
  --ion-color-whatsapp-btn-rgb: 61, 194, 255;
  --ion-color-whatsapp-btn-contrast: #128c7e;
  --ion-color-whatsapp-btn-contrast-rgb: 255, 255, 255;
  --ion-color-whatsapp-btn-shade: #ffffff;
  --ion-color-whatsapp-btn-tint: #dcf8c6;
  --ion-color-phone: #ffffff;
  --ion-color-phone-rgb: 61, 194, 255;
  --ion-color-phone-contrast: #FFCD00;
  --ion-color-phone-contrast-rgb: 255, 255, 255;
  --ion-color-phone-shade: #ffffff;
  --ion-color-phone-tint: #f8e5c6;
  --ion-color-mail: #ffffff;
  --ion-color-mail-rgb: 61, 194, 255;
  --ion-color-mail-contrast: #009ACF;
  --ion-color-mail-contrast-rgb: 255, 255, 255;
  --ion-color-mail-shade: #ffffff;
  --ion-color-mail-tint: #c6dcf8;
  --ion-color-mailto: #009ACF;
  --ion-color-mailto-rgb: 61, 194, 255;
  --ion-color-mailto-contrast: #009ACF;
  --ion-color-mailto-contrast-rgb: 255, 255, 255;
  --ion-color-mailto-shade: #ffffff;
  --ion-color-mailto-tint: #c6dcf8;
  --ion-color-bonus: #ffdd55;
  --ion-color-bonus-rgb: 61, 194, 255;
  --ion-color-bonus-contrast: #ffdd55;
  --ion-color-bonus-contrast-rgb: 255, 255, 255;
  --ion-color-bonus-shade: #ffffff;
  --ion-color-bonus-tint: #c6dcf8;
  --ion-color-clipboard: #2c2c2c;
  --ion-color-clipboard-rgb: 61, 194, 255;
  --ion-color-clipboard-contrast: #2c2c2c;
  --ion-color-clipboard-contrast-rgb: 255, 255, 255;
  --ion-color-clipboard-shade: #ffffff;
  --ion-color-clipboard-tint: #c6dcf8;
  --ion-color-draft: #f6f6f6;
  --ion-color-draft-rgb: 61, 194, 255;
  --ion-color-draft-contrast: #000000;
  --ion-color-draft-contrast-rgb: 255, 255, 255;
  --ion-color-draft-shade: #ffffff;
  --ion-color-draft-tint: #c6dcf8;
  --ion-color-form: #ffcd05;
  --ion-color-form-rgb: 255, 205, 5;
  --ion-color-form-contrast: #ffffff;
  --ion-color-form-contrast-rgb: 255,255,255;
  --ion-color-form-shade: #ffffff;
  --ion-color-form-tint: #ebbc01;
  --ion-color-copy-black: #292929;
  --ion-color-copy-black-rgb: 41, 41, 41;
  --ion-color-copy-black-contrast: #ffffff;
  --ion-color-copy-black-contrast-rgb: 255, 255, 255;
  --ion-color-copy-black-shade: #ffffff;
  --ion-color-copy-black-tint: #009ACF;
  --ion-color-alert: #E32554;
  --ion-color-alert-rgb: 204,24,30;
  --ion-color-alert-contrast: #ffffff;
  --ion-color-alert-contrast-rgb: 255,255,255;
  --ion-color-alert-shade: #b4151a;
  --ion-color-alert-tint: #d12f35;
}
:root .ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook) !important;
  --ion-color-base-rgb: var(--ion-color-facebook-rgb) !important;
  --ion-color-contrast: var(--ion-color-facebook-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-facebook-shade) !important;
  --ion-color-tint: var(--ion-color-facebook-tint) !important;
}
:root .ion-color-google {
  --ion-color-base: var(--ion-color-google) !important;
  --ion-color-base-rgb: var(--ion-color-google-rgb) !important;
  --ion-color-contrast: var(--ion-color-google-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-google-shade) !important;
  --ion-color-tint: var(--ion-color-google-tint) !important;
}
:root .ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter) !important;
  --ion-color-base-rgb: var(--ion-color-twitter-rgb) !important;
  --ion-color-contrast: var(--ion-color-twitter-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-twitter-shade) !important;
  --ion-color-tint: var(--ion-color-twitter-tint) !important;
}
:root .ion-color-whatsapp {
  --ion-color-base: var(--ion-color-whatsapp) !important;
  --ion-color-base-rgb: var(--ion-color-whatsapp-rgb) !important;
  --ion-color-contrast: var(--ion-color-whatsapp-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-whatsapp-shade) !important;
  --ion-color-tint: var(--ion-color-whatsapp-tint) !important;
}
:root .ion-color-whatsapp-btn {
  --ion-color-base: var(--ion-color-whatsapp-btn) !important;
  --ion-color-base-rgb: var(--ion-color-whatsapp-btn-rgb) !important;
  --ion-color-contrast: var(--ion-color-whatsapp-btn-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-whatsapp-btn-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-whatsapp-btn-shade) !important;
  --ion-color-tint: var(--ion-color-whatsapp-btn-tint) !important;
}
:root .ion-color-phone {
  --ion-color-base: var(--ion-color-phone) !important;
  --ion-color-base-rgb: var(--ion-color-phone-rgb) !important;
  --ion-color-contrast: var(--ion-color-phone-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-phone-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-phone-shade) !important;
  --ion-color-tint: var(--ion-color-phone-tint) !important;
}
:root .ion-color-mail {
  --ion-color-base: var(--ion-color-mail) !important;
  --ion-color-base-rgb: var(--ion-color-mail-rgb) !important;
  --ion-color-contrast: var(--ion-color-mail-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-mail-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-mail-shade) !important;
  --ion-color-tint: var(--ion-color-mail-tint) !important;
}
:root .ion-color-mailto {
  --ion-color-base: var(--ion-color-mailto) !important;
  --ion-color-base-rgb: var(--ion-color-mailto-rgb) !important;
  --ion-color-contrast: var(--ion-color-mailto-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-mailto-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-mailto-shade) !important;
  --ion-color-tint: var(--ion-color-mailto-tint) !important;
}
:root .ion-color-bonus {
  --ion-color-base: var(--ion-color-bonus) !important;
  --ion-color-base-rgb: var(--ion-color-bonus-rgb) !important;
  --ion-color-contrast: var(--ion-color-bonus-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-bonus-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-bonus-shade) !important;
  --ion-color-tint: var(--ion-color-bonus-tint) !important;
}
:root .ion-color-clipboard {
  --ion-color-base: var(--ion-color-clipboard) !important;
  --ion-color-base-rgb: var(--ion-color-clipboard-rgb) !important;
  --ion-color-contrast: var(--ion-color-clipboard-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-clipboard-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-clipboard-shade) !important;
  --ion-color-tint: var(--ion-color-clipboard-tint) !important;
}
:root .ion-color-draft {
  --ion-color-base: var(--ion-color-draft) !important;
  --ion-color-base-rgb: var(--ion-color-draft-rgb) !important;
  --ion-color-contrast: var(--ion-color-draft-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-draft-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-draft-shade) !important;
  --ion-color-tint: var(--ion-color-draft-tint) !important;
}
:root .ion-color-form {
  --ion-color-base: var(--ion-color-form) !important;
  --ion-color-base-rgb: var(--ion-color-form-rgb) !important;
  --ion-color-contrast: var(--ion-color-form-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-form-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-form-shade) !important;
  --ion-color-tint: var(--ion-color-form-tint) !important;
}
:root .ion-color-copy-black {
  --ion-color-base: var(--ion-color-copy-black) !important;
  --ion-color-base-rgb: var(--ion-color-copy-black-rgb) !important;
  --ion-color-contrast: var(--ion-color-copy-black-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-copy-black-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-copy-black-shade) !important;
  --ion-color-tint: var(--ion-color-copy-black-tint) !important;
}
:root .ion-color-alert {
  --ion-color-base: var(--ion-color-alert) !important;
  --ion-color-base-rgb: var(--ion-color-alert-rgb) !important;
  --ion-color-contrast: var(--ion-color-alert-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-alert-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-alert-shade) !important;
  --ion-color-tint: var(--ion-color-alert-tint) !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.m-0 {
  margin: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.p-55 {
  padding: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.p-60 {
  padding: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.p-65 {
  padding: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.p-70 {
  padding: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.p-75 {
  padding: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.p-80 {
  padding: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.p-85 {
  padding: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.p-90 {
  padding: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.p-95 {
  padding: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.p-100 {
  padding: 100px !important;
}

.flexGrow {
  flex: 1;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-x::-webkit-scrollbar {
  display: none;
}

.scroll-xy {
  overflow: scroll;
}

.scroll-xy::-webkit-scrollbar {
  display: none;
}

.mw-100 {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.clickable {
  transition: transform 0.2s; /* Animation */
}
.clickable:not(.grab):hover {
  cursor: pointer;
  transform: scale(1.05);
}

.text-center {
  text-align: center;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

ngx-gallery-preview {
  top: 80px !important;
}

.ngx-gallery-thumbnail {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
}
.ngx-gallery-thumbnail .ngx-gallery-active {
  border: 1px solid var(--ion-color-secondary) !important;
}

ngx-file-drop {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dropFileZone {
  height: 100%;
  width: 100%;
}

.dropFileContent {
  display: none;
  color: var(--ion-color-primary);
  font-size: 18px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  justify-content: center;
}

.btn-wrap-text {
  white-space: pre-line;
}

.fitPopover {
  --width: 400px !important;
}

.groupedBindingSelectorPopover {
  --width: 200px !important;
}

.groupedBindingSelectorPopoverMobile {
  --width: 200px !important;
  --height: 170px !important;
  --backdrop-opacity: 0.4;
  --offset-x: 100px;
  --offset-y: 0px;
}

.alertCustom {
  --max-width: 400px !important;
}
.alertCustom .alert-title {
  font-size: 20px;
}
.alertCustom .alert-message {
  font-size: 15px;
}
.alertCustom .alertGrayButton {
  color: gray;
}
.alertCustom button {
  font-weight: 500 !important;
}

#cookiescript_injected {
  bottom: 40px !important;
}

.swiper-wrapper {
  padding-left: 0 !important;
}

ion-searchbar .searchbar-input-container input {
  font-family: "Barlow Semi Condensed", sans-serif !important;
  font-size: 23px !important;
  line-height: 23px !important;
  font-weight: 500 !important;
  color: black !important;
  background: white !important;
}
ion-searchbar .searchbar-input-container ion-icon {
  color: black !important;
}

.grey-border {
  border: 1px solid #737373;
  border-radius: 6px;
}

/*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
/*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
mat-select {
  font-weight: 500;
  border: 1px solid #737373;
  border-radius: 6px;
  padding: 6px 15px;
  width: fit-content !important;
  min-width: 100% !important;
  /*
  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
  */
  /*
  TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
  */
  /*
  TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
  */
}
mat-select .mat-form-field-infix {
  min-width: 75px !important;
  width: fit-content !important;
}
mat-select .mat-select-value {
  width: fit-content !important;
  min-width: 2ch !important;
  max-width: 25ch !important;
}
mat-select .mat-select-placeholder {
  width: fit-content;
  min-width: 10ch;
}

.fitWidthToast {
  --width: fit-content !important;
}
.fitWidthToast::part(message) {
  font-size: 16px;
}

.successToast::part(message) div {
  @apply flex flex-row items-center;
}
.successToast::part(message) ion-icon {
  width: 30px;
  height: 30px;
}
.successToast::part(container) {
  border-radius: 15px;
  outline: 4px solid rgba(10, 207, 131, 0.3);
  background: #0ACF83;
}

.iconWhite {
  fill: white;
}

.popover-wrapper {
  width: fit-content;
  height: auto;
}

ion-back-button {
  content: url(/assets/icon/flechaIzquierda.svg);
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.displayFlex {
  display: flex;
}

.title1 {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
}

.title2 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.regularText {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  ion-toast {
    transform: translateY(-75px) !important;
  }
}
.tooltip-list {
  text-align: left !important;
}
.tooltip-list ol {
  padding-inline-start: 20px;
}

.info-popover {
  padding: 10px;
  --width: 350px !important;
}
.info-popover img {
  object-fit: contain;
}
.info-popover lottie-player {
  width: 55px;
}
.info-popover .title {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 5px;
  color: #949494;
}
.info-popover .section {
  padding: 10px;
  background: #F6F6F6;
  border-radius: 10px;
  width: 100%;
}
.info-popover .section .section_title {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 2px;
}
.info-popover .section .section_info {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.grouped-file-list {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 20px;
}
.grouped-file-list .mat-grid-tile-content {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: start;
  height: 100%;
  margin: 0;
  row-gap: 10px;
}
.grouped-file-list .mat-grid-tile-content .file-container {
  width: 100%;
}

ul {
  list-style: disc;
  padding: inherit;
}

@media (min-width: 1104px) and (max-width: 1815px) {
  ion-split-pane, ion-menu {
    zoom: 0.75;
  }
}