// space generation variables
$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45,
50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // all positions


//pl-30 mt-20
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

//m-15 p-20
@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
}

.flexGrow {
  flex: 1;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-x::-webkit-scrollbar {
  display: none;
}

.scroll-xy {
  overflow: scroll;
}

.scroll-xy::-webkit-scrollbar {
  display: none;
}

.mw-100 {
  max-width: 100%;
}

.w-100 {
  width: 100%;
}

.clickable {
  transition: transform .2s; /* Animation */
  &:not(.grab):hover {
    cursor: pointer;
    transform: scale(1.05);
  }
}

//Text
.text-center {
  text-align: center;
}

//line-clamp
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
