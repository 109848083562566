@import "./mixins/ion-color";

// Add custom colors to use with [color] property
// (see: https://forum.ionicframework.com/t/adding-custom-colours-to-use-with-color-property/136919)
// GLOBAL COLORS:
//    You can define new Ionic colors here (inside :root{ ... }) to be available within the entire app
// LOCAL COLORS:
//    You can also define new Ionic colors in each page (inside :host{ ... }) to be available just in that page
//    (We have an example of local custom Ionic colors in the deals pages)
:root {
  @include ion-color('facebook') {
    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59,89,152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255,255,255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;
  }
  // @include ion-color('instagram') {
  //   --ion-color-instagram: #C13584;
  //   --ion-color-instagram-rgb: 59,89,152;
  //   --ion-color-instagram-contrast: #ffffff;
  //   --ion-color-instagram-contrast-rgb: 255,255,255;
  //   --ion-color-instagram-shade: #833AB4;
  //   --ion-color-instagram-tint: #E1306C;
  // }
  // @include ion-color('youtube') {
  //   --ion-color-youtube: #c4302b;
  //   --ion-color-youtube-rgb: 59,89,152;
  //   --ion-color-youtube-contrast: #ffffff;
  //   --ion-color-youtube-contrast-rgb: 255,255,255;
  //   --ion-color-youtube-shade: #c4302b;
  //   --ion-color-youtube-tint: #c4302b;
  // }
  // @include ion-color('tiktok') {
  //   --ion-color-tiktok: black;
  //   --ion-color-tiktok-rgb: 59,89,152;
  //   --ion-color-tiktok-contrast: #ffffff;
  //   --ion-color-tiktok-contrast-rgb: 255,255,255;
  //   --ion-color-tiktok-shade: black;
  //   --ion-color-tiktok-tint: black;
  // }

  @include ion-color('google') {
    --ion-color-google: #cc181e;
    --ion-color-google-rgb: 204,24,30;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255,255,255;
    --ion-color-google-shade: #b4151a;
    --ion-color-google-tint: #d12f35;
  }

  @include ion-color('twitter') {
    --ion-color-twitter: #00aced;
    --ion-color-twitter-rgb: 0,172,237;
    --ion-color-twitter-contrast: #ffffff;
    --ion-color-twitter-contrast-rgb: 255,255,255;
    --ion-color-twitter-shade: #0097d1;
    --ion-color-twitter-tint: #1ab4ef;
  }

  @include ion-color('whatsapp') {
    --ion-color-whatsapp: #128c7e;
    --ion-color-whatsapp-rgb: 61, 194, 255;
    --ion-color-whatsapp-contrast: #128c7e;
    --ion-color-whatsapp-contrast-rgb: 255, 255, 255;
    --ion-color-whatsapp-shade: #ffffff;
    --ion-color-whatsapp-tint: #dcf8c6;
  }
  @include ion-color('whatsapp-btn') {
    --ion-color-whatsapp-btn: #ffffff;
    --ion-color-whatsapp-btn-rgb: 61, 194, 255;
    --ion-color-whatsapp-btn-contrast: #128c7e;
    --ion-color-whatsapp-btn-contrast-rgb: 255, 255, 255;
    --ion-color-whatsapp-btn-shade: #ffffff;
    --ion-color-whatsapp-btn-tint: #dcf8c6;
  }

  @include ion-color('phone') {
    --ion-color-phone: #ffffff;
    --ion-color-phone-rgb: 61, 194, 255;
    --ion-color-phone-contrast: #FFCD00;
    --ion-color-phone-contrast-rgb: 255, 255, 255;
    --ion-color-phone-shade: #ffffff;
    --ion-color-phone-tint: #f8e5c6;
  }
  @include ion-color('mail') {
    --ion-color-mail: #ffffff;
    --ion-color-mail-rgb: 61, 194, 255;
    --ion-color-mail-contrast: #009ACF;
    --ion-color-mail-contrast-rgb: 255, 255, 255;
    --ion-color-mail-shade: #ffffff;
    --ion-color-mail-tint: #c6dcf8;
  }
  @include ion-color('mailto') {
    --ion-color-mailto: #009ACF;
    --ion-color-mailto-rgb: 61, 194, 255;
    --ion-color-mailto-contrast: #009ACF;
    --ion-color-mailto-contrast-rgb: 255, 255, 255;
    --ion-color-mailto-shade: #ffffff;
    --ion-color-mailto-tint: #c6dcf8;
  }
  

  @include ion-color('bonus') {
    --ion-color-bonus: #ffdd55;
    --ion-color-bonus-rgb: 61, 194, 255;
    --ion-color-bonus-contrast: #ffdd55;
    --ion-color-bonus-contrast-rgb: 255, 255, 255;
    --ion-color-bonus-shade: #ffffff;
    --ion-color-bonus-tint: #c6dcf8;
  }

  @include ion-color('clipboard') {
    --ion-color-clipboard: #2c2c2c;
    --ion-color-clipboard-rgb: 61, 194, 255;
    --ion-color-clipboard-contrast: #2c2c2c;
    --ion-color-clipboard-contrast-rgb: 255, 255, 255;
    --ion-color-clipboard-shade: #ffffff;
    --ion-color-clipboard-tint: #c6dcf8;
  }

  @include ion-color('draft') {
    --ion-color-draft: #f6f6f6;
    --ion-color-draft-rgb: 61, 194, 255;
    --ion-color-draft-contrast: #000000;
    --ion-color-draft-contrast-rgb: 255, 255, 255;
    --ion-color-draft-shade: #ffffff;
    --ion-color-draft-tint: #c6dcf8;
  }
  @include ion-color('form') {
    --ion-color-form: #ffcd05;
    --ion-color-form-rgb: 255, 205, 5;
    --ion-color-form-contrast: #ffffff;
    --ion-color-form-contrast-rgb: 255,255,255;
    --ion-color-form-shade: #ffffff;
    --ion-color-form-tint: #ebbc01;
  }  

  @include ion-color('copy-black') {
    --ion-color-copy-black: #292929;
    --ion-color-copy-black-rgb: 41, 41, 41;
    --ion-color-copy-black-contrast: #ffffff;
    --ion-color-copy-black-contrast-rgb: 255, 255, 255;
    --ion-color-copy-black-shade: #ffffff;
    --ion-color-copy-black-tint: #009ACF;
  }  

  @include ion-color('alert') {
    --ion-color-alert:  #E32554;
    --ion-color-alert-rgb: 204,24,30;
    --ion-color-alert-contrast: #ffffff;
    --ion-color-alert-contrast-rgb: 255,255,255;
    --ion-color-alert-shade: #b4151a;
    --ion-color-alert-tint: #d12f35;
  }  
}
