/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

// Swiper
//TODO J.A. ELIMINAR YA NO DEBERÍA HACER FALTA
// @import '~swiper/swiper.scss';
// @import 'node_modules/swiper/swiper.scss';
// @import '~swiper/swiper-bundle.css';
// @import '~@ionic/angular/css/ionic-swiper';
// @import 'app/components/swiper-custom.scss';

// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';

:root {
  --width: 900px;
  color-scheme: only light;
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-bullet-horizontal-gap: 20px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-vertical-gap: 2px;
  --swiper-pagination-top: auto;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-progressbar-size: 24px;
  --swiper-theme-color: var(--ion-color-primary);

}


swiper-container::part(bullet-active) {
  width: 15px;
  height: 15px !important;
}

swiper-container::part(pagination) {
  position: static;
}

.poppins {
  font-family: 'Poppins';
}

.montserrat {
  font-family: 'Montserrat';
}

.league-spartan {
  font-family: "League Spartan", sans-serif;
}

.raleway {
  font-family: "Raleway", sans-serif;
}

.barlow {
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.gloria-hallelujah {
  font-family: 'Gloria Hallelujah', cursive;
}

.dark-background {
  background: var(--ion-color-medium-dark);
}

.boxed-content {
  max-width: var(--boxed-size);
  margin: auto;
  padding: 40px 10%;

  &.dark {
    background: var(--ion-color-medium-dark);
  }
}

@media screen and (max-width: 500px) {
  .boxed-content {
    padding: 20px 40px
  }
}

.app-toolbar {
  --background: black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  height: 85px !important;
  display: flex;
  padding-inline: 10px;

  ion-title {
    color: white;
  }
}

.custom-ngx-gallery {
  border: 2px solid red;
}

ion-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;

  ion-toolbar {
    --background: white !important;
  }

  ion-toolbar {
    --border-width: 0 !important;
  }

  &::part(content) {
    --width: 800px;
    --max-width: 96%;
    background: white !important;
  }

  &.simulatorModal {
    &::part(content) {
      --width: 958px;
    }

    --height: 1110px;
  }

  &.auto-height {
    --height: auto;

    &::part(content) {
      // width: 90vw;
      overflow: auto;
      position: relative;
      contain: content;
      max-height: 90vh !important;

      .modal-content {
        overflow: auto;
      }
    }

    /*.ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
      }
    }*/
  }

  &.wide {
    &::part(content) {
      --width: 90%;
      --max-width: 1200px;
    }
  }

  &.modal-address {
    --border-radius: 20px;

    &::part(content) {
      padding: 30px;
    }
  }

  &.categories-modal {
    --border-radius: 20px;

    &::part(content) {
      --width: 690px !important;
      padding: 20px;
    }
  }

  &.order-detail-modal {
    --border-radius: 20px;
    --height: auto;

    &::part(content) {
      padding: 30px;
      max-height: 90vh !important;
    }
  }

  &.order-repeat-modal {
    --border-radius: 20px;
    --height: auto;

    &::part(content) {
      padding: 20px;
      max-height: 90vh !important;
    }
  }

  &.order-report-incident-modal {
    --border-radius: 20px;
    --height: auto;

    &::part(content) {
      padding: 20px;
      max-height: 90vh !important;
    }
  }
}

.popup-confirm {
  --backdrop-opacity: 0.4;

  &::part(content) {
    --max-width: 80%;
    width: 540px;
  }
}

.popup-product-added {
  --backdrop-opacity: 0.4;
  --max-width: 90%;

  @media only screen and (min-width: 768px) {
    --max-width: 400px;
  }
}

.popup-product-qty {
  --backdrop-opacity: 0.4;
  --min-height: 320px;

  @media only screen and (min-width: 768px) {
    --width: 500px;
  }

  // div.modal-wrapper{
  //   .ion-page{
  //     // justify-content: center !important;
  //     // display: block;
  //   }
  // }
  &::part(content) {
    --max-width: 80%;
    width: 540px;
  }
}

.promotion-dialog {
  @media only screen and (min-width: 768px) {
    --height: 500px;
  }
}

.popup-before-pay {
  @media only screen and (min-width: 768px) {
    //--height: 600px;
    --width: 800px;
  }

  ion-slide {
    width: 40%;
    max-width: 250px;
    height: fit-content;
  }
}

.popup-pickup-points {
  --height: 700px;
}

.popup-add-document {
  --backdrop-opacity: 0.4;
  --height: 800px;
  --max-height: calc(95vh);
  --width: 700px;
  --max-width: calc(100vh);
}

.popup-grouped-binding-individual-settings {
  &::part(content) {
    --width: 355px;
  }
}

.modal-width-400 {
  &::part(content) {
    --width: 400px;
  }
}


.popup-browser-share {
  --backdrop-opacity: 0.4;
  --border-radius: 20px;

  &::part(content) {
    --max-width: 80%;
    width: 631px;
  }
}

.popup-tooltip {

  // --backdrop-opacity: 0.4;
  &::part(content) {
    // --max-width: 250px;
    width: 350px;
    --height: 620px;
    --max-height: 80%;
    --max-width: 80%;
    overflow: hidden !important;
    // position: absolute;
    // right: 0;
    // top: 0;
  }
}

.popup-bonus-warning {
  --border-radius: 30px;
}

tooltip.tooltip {
  // home tooltip (and any other?)
  background-color: var(--app-black) !important;
  color: var(--ion-color-primary-contrast) !important;
  border-radius: 10px !important;
  font-size: 14px;
  font-weight: 400;

  &.white-tooltip {
    background-color: white !important;
    color: black !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;

    &:after {
      border-color: white transparent transparent transparent;
    }
  }

  &.red-tooltip {
    background-color: var(--ion-color-danger) !important;
    color: white !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;

    &:after {
      border-color: white transparent transparent transparent;
    }
  }

  &.pink-tooltip {
    background-color: #FF007F !important;
    color: white !important;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25) !important;


    &:after {
      border-color: #FF007F transparent transparent transparent;
      transform: scaleY(-1) !important;
    }
  }
}

// this below works, but arrows are all black anyway
// tooltip.tooltip-left::after{
//   border-color: transparent transparent transparent var(--app-black) !important;
//   // border-color: transparent transparent transparent red !important;
// }

ion-footer {
  ion-grid {
    --ion-grid-padding: 0px;
    --ion-grid-column-padding: 0px;
  }
}

ion-button {
  --border-width: 2px;

  &.no-padding-button {
    &::part(native) {
      padding: 0 !important
    }
  }

  // font-weight: 600;
}

.banner-img {
  width: 150px;
  margin: auto;
}

.border {
  border: 1px solid black;
}

.show-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// EXTRA GLOBAL SYLES
@import "./theme/app.scss";
@import "./theme/custom-ion-colors.scss";
@import "./theme/utility.scss";

// ngx-gallery-preview.ngx-gallery-active{
//   top: 80px !important;
// }
ngx-gallery-preview {
  top: 80px !important;
}

.ngx-gallery-thumbnail {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;

  .ngx-gallery-active {
    border: 1px solid var(--ion-color-secondary) !important;
  }
}

ngx-file-drop {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}

.dropFileZone {
  height: 100%;
  width: 100%;

}

.dropFileContent {
  display: none;
  color: var(--ion-color-primary);
  font-size: 18px;
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  //display: flex;
  justify-content: center;
}

.btn-wrap-text {
  white-space: pre-line;
}

.fitPopover {
  --width: 400px !important;
}

.groupedBindingSelectorPopover {
  --width: 200px !important;
}

.groupedBindingSelectorPopoverMobile {
  --width: 200px !important;
  --height: 170px !important;
  --backdrop-opacity: 0.4;
  --offset-x: 100px;
  --offset-y: 0px;
}

.alertCustom {
  --max-width: 400px !important;

  .alert-title {
    font-size: 20px;
  }

  .alert-message {
    font-size: 15px;
  }

  .alertGrayButton {
    color: gray;
  }

  button {
    font-weight: 500 !important;
  }
}

#cookiescript_injected {
  bottom: 40px !important;
}

.swiper-wrapper {
  padding-left: 0 !important;

}

ion-searchbar {
  .searchbar-input-container {
    input {
      font-family: 'Barlow Semi Condensed', sans-serif !important;
      font-size: 23px !important;
      line-height: 23px !important;
      font-weight: 500 !important;
      color: black !important;
      background: white !important;
    }

    ion-icon {
      color: black !important;
    }
  }
}

.grey-border {
  border: 1px solid #737373;
  border-radius: 6px;
}

/*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
/*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
mat-select {
  font-weight: 500;
  border: 1px solid #737373;
  border-radius: 6px;
  padding: 6px 15px;
  width: fit-content !important;
  min-width: 100% !important;
  /*
 TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
  .mat-form-field-infix {
    min-width: 75px !important;
    width: fit-content !important;
  }

  /*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
  .mat-select-value {
    width: fit-content !important;
    min-width: 2ch !important;
    max-width: 25ch !important;
  }

  /*
 TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
 */
  .mat-select-placeholder {
    width: fit-content;
    min-width: 10ch;
  }
}

.fitWidthToast {
  --width: fit-content !important;

  &::part(message) {
    font-size: 16px
  }
}

.successToast {
  &::part(message) {
    div {
      @apply flex flex-row items-center;
    }

    ion-icon {
      width: 30px;
      height: 30px;
    }
  }

  &::part(container) {


    border-radius: 15px;
    outline: 4px solid rgba(10, 207, 131, 0.30);
    background: #0ACF83;
  }
}

.iconWhite {
  fill: white
}

.popover-wrapper {
  width: fit-content;
  height: auto;
}

ion-back-button {
  content: url(/assets/icon/flechaIzquierda.svg);
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.displayFlex {
  display: flex
}

.title1 {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
}

.title2 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.regularText {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  ion-toast {
    transform: translateY(-75px) !important;
  }
}

.tooltip-list {
  text-align: left !important;

  ol {
    padding-inline-start: 20px;
  }
}

.info-popover {
  padding: 10px;
  --width: 350px !important;

  img {
    object-fit: contain;
  }

  lottie-player {
    width: 55px;
  }

  .title {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 5px;
    color: #949494;
  }

  .section {
    padding: 10px;
    background: #F6F6F6;
    border-radius: 10px;
    width: 100%;


    .section_title {
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      margin-bottom: 2px;
    }

    .section_info {
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.grouped-file-list {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 20px;

  .mat-grid-tile-content {

    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: start;
    height: 100%;
    margin: 0;
    row-gap: 10px;

    .file-container {
      width: 100%;
    }
  }
}

ul {
  list-style: disc;
  padding: inherit;
}

//UGLY TEMPORAL PATCH
@media (min-width: 1104px) and (max-width: 1815px) {
  ion-split-pane, ion-menu {
    zoom: 0.75
  }
}


